import { Dialog2, LoadingFullScreen, Input, Checkbox, Select } from 'components'

export const EditVotingCategoryDialog = ({ attrs: { loading, awardsYearId, votingCategory } }) => {
	loading = false

	const formData = {
		id: votingCategory.id,
		awardsYearId: awardsYearId,
		name: votingCategory.name,
		subgroupId: votingCategory.subgroupId,
		shortName: votingCategory.shortName,
		isSeparatePersonEntries: votingCategory.isSeparatePersonEntries,
		sortOrder: votingCategory.sortOrder,
		votingCategoryTypeId: votingCategory.votingCategoryTypeId,
		isShowEntryCharacter: votingCategory.isShowEntryCharacter,
		isShowEntryRole: votingCategory.isShowEntryRole,
	}

	return {
		view({ attrs: { loading, votingCategory, open, onsubmit, votingCategoryTypes, subgroups } }) {
			return m(
				Dialog2,
				{
					open,
					save() {
						loading = true
						onsubmit(formData)
					},
					saving: loading,
					heading: 'Edit Voting Category',
					classes: ['md:max-w-2xl'],
					cancelLabel: 'Close',
				},
				[
					votingCategory
						? m('.flex.flex-col.gap-3', [
								m(Input, {
									label: 'Name',
									value: formData.name,
									type: 'text',
									required: true,
									oninput(value) {
										formData.name = value
									},
								}),
								m(Input, {
									label: 'Short Name',
									value: formData.shortName,
									type: 'text',
									required: true,
									oninput(value) {
										formData.shortName = value
									},
								}),
								m('.grid.grid-cols-2.gap-x-3', [
									m('div', [
										m(Select, {
											label: 'Category Type',
											value: formData.votingCategoryTypeId,
											required: true,
											choices: votingCategoryTypes.map((type) => {
												return { label: type.name, value: type.id }
											}),
											onchange(val) {
												formData.votingCategoryTypeId = parseInt(val)
											},
										}),
									]),
									m('div', [
										m(Select, {
											label: 'Subgroup',
											value: formData.subgroupId,
											required: false,
											choices: subgroups.map((subgroup) => {
												return { label: subgroup.name, value: subgroup.id }
											}),
											onchange(val) {
												formData.subgroupId = parseInt(val)
											},
										}),
									]),
								]),
								m('.grid.grid-cols-2.gap-x-3', [
									m('div.mt-3', [
										m(Checkbox, {
											label: 'Separate Person Entries?',
											checked: formData.isSeparatePersonEntries,
											onchange() {
												formData.isSeparatePersonEntries = !formData.isSeparatePersonEntries
											},
										}),
									]),
									m(Input, {
										label: 'Sort Order',
										value: formData.sortOrder,
										type: 'number',
										required: true,
										oninput(value) {
											formData.sortOrder = value
										},
									}),
									m('div.mt-3', [
										m(Checkbox, {
											label: 'Show Entry Role?',
											checked: formData.isShowEntryRole,
											onchange() {
												formData.isShowEntryRole = !formData.isShowEntryRole
											},
										}),
									]),
									m('div.mt-3', [
										m(Checkbox, {
											label: 'Show Entry Character?',
											checked: formData.isShowEntryCharacter,
											onchange() {
												formData.isShowEntryCharacter = !formData.isShowEntryCharacter
											},
										}),
									]),
								]),
						  ])
						: m(LoadingFullScreen),
				]
			)
		},
	}
}
